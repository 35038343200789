.lbbody {
	float: left;
	padding-top: 12px;
	padding-bottom: 12px;
	width: 100%;
	font-weight: 500;
	height: 520px;
	overflow-x: scroll;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	-webkit-transform: translateZ(0px);
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-perspective: 1000;
}

.lbtable {
	display: table;
	width: 100%;
	height: 520px;
	padding-left: 2px;
	padding-right: 1px;
}
.lbrow {
	display: table-row;
	padding-top: 7px;
	padding-bottom: 7px;
	border-bottom: 1px solid #ccc;
	width: 100%;
	clear: both;
	float: left;
}

.lbrowselected {
	display: table-row;
	padding-top: 7px;
	padding-bottom: 7px;
	border-bottom: 1px solid #ccc;
	width: 100%;
	clear: both;
	float: left;
	background: #ebf8ee;
	color: #18b459;
}

.lbcol {
	float: left; /* fix for  buggy browsers */
	display: table-column;
	width: 33.333%;
	padding-left: 12px;
}

.lbheader {
	float: left; /* fix for  buggy browsers */
	/* display: table-column;          */
	padding-top: 12px;
	padding-bottom: 12px;
	width: 100%;
	font-weight: 500;
	color: white;
	background-color: #259a35;
}

.lbheadercol {
	float: left; /* fix for  buggy browsers */
	/* display: table-column;          */
	width: 33.333%;
	padding-left: 12px;
}
