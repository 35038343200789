@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.banner {
  .banner-bg {
    background-image: url("../../images/banner.jpg");
    background-size: cover;
    height: 280px;
    width: 100%;
    .banner-content {
      h2 {
        color: $navlink-clr;
        padding-top: 50px;
        font-family: "D-DIN Exp";
        font-weight: bold;
        font-size: 40px;
      }
      p {
        color: #727272;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}
