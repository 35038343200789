.tutorial {
	a {
		text-decoration: none;
		cursor: pointer;
		&:hover {
			text-decoration: none;
		}
		display: block;
		padding: 80px 0;
		.memberships {
			background: #fcfcfc;
			display: flex;
			align-items: center;
			flex-direction: column;
			margin: 0 auto;
			width: 80%;
			& > img {
				width: 100%;
			}
			.title {
				margin-bottom: 60px;
				color: #2b2a2a;
				img {
					margin-right: 20px;
					width: auto;
				}
			}
			.bottom {
				width: 100%;
			}
		}
	}
	.video-description {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 140px 50px 100px 100px;
		background-image: url("/static/images/silver-stonks-staking-background.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: max-content;

		.title-description {
			width: 30%;
			.title {
				margin-bottom: 10px;
				font-size: 18px;
			}
		}
		.video-tutorial {
			width: 55%;
			padding-left: 40px;
			iframe {
				min-height: unset;
				height: 500px;
			}
		}
	}
	@media (max-width: 1100px) {
		.video-description {
			padding: 0 15px;
			flex-direction: column-reverse;
			.title-description {
				width: 100%;
				padding: 0 0 30px 0;
				.title{
					text-align: center;

				}
			}

			.video-tutorial {
				padding: 20px 0 20px 0;
				width: 100%;

				iframe {
					max-height: 300px;
					height: 300px;
				}
			}
		}
		a {
			padding: 40px 0;
			.memberships {
				width: 100% !important;
				.title {
					margin-bottom: 20px;
					font-size: 20px;
				}
				.bottom {
					img {
						width: 30%;
					}
				}
			}
		}
	}
	@media (max-width: 568px) {
		.video-description {
			
			.video-tutorial {
				iframe {
					max-height: 200px;
					height: 200px;
				}
			}}
	}
}
