@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

$DN-Exp: "D-DIN Exp";
$popins: "Poppins", sans-serif;

// stake-form
// leaderboard

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.stake-form {
  .stake-form-content {
    h2 {
      font-size: 30px;
      font-family: $DN-Exp;
      font-weight: bold;
    }
    p {
      font-size: 15px;
      font-weight: lighter;
      font-family: $popins;
      padding-top: 20px;
      color: #6e7e98;
    }
  }
  .form {
    .list-inline {
      .list-inline-item {
        input {
          padding: 0px 100px 0px 10px;
        }
      }
    }
    .amount {
      margin-top: 35px;
      label {
        font-size: 15px;
        font-weight: bold;
        font-family: $popins;
      }
      input {
        width: 53%;
        @media screen and (max-width: "425px") {
          width: 100%;
        }
      }
    }
    .date {
      margin-top: 35px;

      .list-inline {
        .list-inline-item {
          label {
            font-size: 15px;
            font-weight: bold;
            font-family: $popins;
          }
          input {
            padding: 0px 100px 0px 10px;
            @media screen and (max-width: "425px") {
              padding: 0px 130px 0px 10px;
            }
          }
        }
      }
    }
    .earned {
      margin-top: 35px;

      .list-inline {
        .list-inline-item {
          label {
            font-size: 15px;
            font-weight: bold;
            font-family: $popins;
          }
          // input {
          //   padding: 0px 100px 0px 10px;
          // }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .form{
      input,button{
        margin-bottom: 20px;
        margin-left: 0;
      }
    }
  }
}
// leaderboard
.leader-board {
  .table-border {
    border: 1px solid #6e7e98;
    .table-content {
      padding: 30px 0px 10px 20px;
      h3 {
        font-size: 30px;
        font-family: $DN-Exp;
        font-weight: bold;
      }
      p {
        font-family: $popins;
      }
    }
    .table {
      tbody {
        display: block;
        height: 290px;
        overflow: auto;
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }
      thead {
        width: calc(
          103% - 1em
        ); /* scrollbar is average 1em/16px width, remove it from thead width */
      }
    }
  }
  // ::-webkit-scrollbar {
  //   display: none;
  // }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $navabr-bg;
  }

  .table-bordered td,
  .table-bordered th {
    border: none;
    border-bottom: 1px solid #6e7e98;
  }
}
label {
  font-size: 15px;
  font-weight: bold;
  font-family: $popins;
}