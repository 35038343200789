.abcontainer {
    width: 100%;
    font-weight: 300;
    padding-bottom:10px;
}

.leftdiv {
    padding-right:18px;
    width:57%;
    float: left;
    color:green;
}

.rightdiv {
    margin-left: 30%;
    height: 100px;
}

.leftdivauthorized {
    color:black;
    font-size: 1.1em;
}

.leftdivauthorized span {
    color:green;
}